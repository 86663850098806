<template>
  <div class="KakaoHome">
    <!--★ Map 보여주는 곳 ★ -->
    <div class="kmap" ref="map"></div>

    <!-- Toast msg (0건) -->
    <MyToast :toast_msg= 'toast1_msg' :toast_msg2= 'toast1_msg2' :toast_ui= "toast1_ui" ></MyToast>
    <!-- Toast msg (있을 경우) -->
    <MyToast :toast_msg= 'toast2_msg' :toast_msg2= 'toast2_msg2' :toast_ui= "toast2_ui" ></MyToast>

    <!-- Zoom제한 메시지 -->
    <MyToast2 :toast_msg= 'toast3_msg' :toast_msg2= 'toast3_msg2' :toast_ui= "toast3_ui" ></MyToast2>

    <!-- 에러개선 GPS정보를 받아오기 전에 default 좌표지도가 보이지 않게 가려버림 -->
    <div class="progress" v-if="progress_UI==true">
    </div>

    <div class = "debugMarkbottom" v-if="CollectionType =='post_debug'">
      Debug 17.0
    </div>
    
  </div>

</template>

<script>
import {db} from './firebaseConfig'
import "firebase/firestore";
import {geohashQueryBounds} from "geofire-common";
import {distanceBetween} from "geofire-common";
// eslint-disable-next-line
import MyToast from "./MyToast.vue";
import MyToast2 from "./MyToast2.vue";

  // ★ 라이브러리를 Index.html에 정의했기에 vue특성상 전역변수 window를 써줘야 동작함 ★
  let kakao = window.kakao;

export default {
  name: 'KakaoHome',
  components: {
    // eslint-disable-next-line
    MyToast,
    MyToast2,
  },// Components
  data() {
    return{
      // default 좌표지도를 Hide하기 위함
      progress_UI : true,
      // DB에서 가져온 request 데이터
      requestdb:[],
      // 선택한 marker에 매칭된 Request 데이터
      selected:[], // DB전체
      selected_summary:{ // 선택된 장소 & 주소 
        location :"",
        address:"",
      },

      // 지도 초기 옵션값
      options:{
        center: {
          lat: 37.28305700556231,
          lng: 127.04366229466967,
        },
        level: 4,
      },
      // postCollection 종류 (release or Debug)
      CollectionType : "post",
      // block한 user uid
      blockUserList : [],

      //toast1  (내주변 궁금해요 없을 경우 )
      toast1_msg:"내 주변 궁금해요",
      toast1_msg2 : 0,
      toast1_ui : false,

      //toast2  (내주변 궁금해요 있을 경우 )
      toast2_msg:"내 주변 궁금해요",
      toast2_msg2 : 0,
      toast2_ui : false,

      //toast3 MyToast2  ( Zoom 알림 )
      toast3_msg:"걸어서 30분 거리까지",
      toast3_msg2 : "조회됩니다.",
      toast3_ui : false,

    }
  },// End Data
  methods:{

    // APPtoWeb 요청함수 (App의 GPS정보를 받아오는 함수, collection정보도 받아옴 release인지 debug인지)
    mygps(position,postCollection,blockList){

      //console.log("★ APP GPS받아옴 9 ★")
      this.CollectionType = postCollection //(추가) postCollection 연결 (release or Debug)

      /// data처리(에러개선) ["sad","sda"] 이렇게 App에저 전송하면
      /// web에서 수신하는 데이터는 "[sad,sda]" 이렇게 전송받는다... 전처리해야 Array로 사용가능
      if(blockList != "[]"){
        const jsonString = blockList.replace(/^\[|\]$/g, ''); // 대활호 제거
        const dataArray = jsonString.split(",").map(item => item.trim()); //,로 분리된걸 Array로 전환 (1개만 있어도 가능!)
        this.blockUserList = dataArray
      }else{
        //console.log("로그아웃 상태");
        this.blockUserList = [];
      }
      

      var changeposition= ()=> {
        return new Promise((resolve) => {
          this.options.center.lat = Number(JSON.parse(position)[0])
          this.options.center.lng = Number(JSON.parse(position)[1])
          resolve()

        })
      }
      var webview_mounted = () => {
        changeposition().then(() =>{
          // APP으로 부터 받은 위치정보로 DB GET & 마커 등 생성 
          this.after_gps_change()
          // setTimeout으로 안하고 그냥 center를 잡으니 좀 이상함..그리고 오히려 setTimeout하니 더 빠르게 로딩됨.
          setTimeout(()=>{
            this.mapInstance.setCenter(new kakao.maps.LatLng(this.options.center.lat, this.options.center.lng));
            this.progress_UI = false; // default좌표 hide하는 UI삭제
          }, 100);

        })
      }
      webview_mounted()

    },
    // ★마운트시 GPS값을 받고나서 DB_GET 실행 및 내위치, 마커등 표시★
    after_gps_change(){
      this.DB_GET();
      this.maploading();

    },
    // refresh 
    refresh(position,postCollection,blockList){
      
      this.CollectionType = postCollection //(추가) postCollection 연결 (release or Debug)

      /// data처리(에러개선) ["sad","sda"] 이렇게 App에저 전송하면
      /// web에서 수신하는 데이터는 "[sad,sda]" 이렇게 전송받는다... 전처리해야 Array로 사용가능
      if(blockList != "[]"){
        const jsonString = blockList.replace(/^\[|\]$/g, ''); // 대활호 제거
        const dataArray = jsonString.split(",").map(item => item.trim()); //,로 분리된걸 Array로 전환 (1개만 있어도 가능!)
        this.blockUserList = dataArray
      }else{
        //console.log("로그아웃 상태");
        this.blockUserList = [];
      }

      // 1st. 기존 marker와 cluster제거
      this.remove()

      // 2nd. 위치 업데이트
      var updateposition= ()=> {
        return new Promise((resolve) => {
          this.options.center.lat = Number(JSON.parse(position)[0])
          this.options.center.lng = Number(JSON.parse(position)[1])
          resolve()
          this.progress_UI = false;
        })
        
      }
      

      var webview_refresh = () => {
        updateposition().then(() =>{
          // APP으로 부터 받은 위치정보로 DB GET & 마커 등 생성 
          this.after_gps_change()
          this.mapInstance.setCenter(new kakao.maps.LatLng(this.options.center.lat, this.options.center.lng));
          // setTimeout으로 안하고 그냥 center를 잡으니 좀 이상함..그리고 오히려 setTimeout하니 더 빠르게 로딩됨.
          setTimeout(()=>{
            this.mapInstance.setCenter(new kakao.maps.LatLng(this.options.center.lat, this.options.center.lng));
          },500);

        })
      }
      webview_refresh()

      // 클릭시 zoom level 7이상 이면 zoom 3level로 확대
      if(this.mapInstance.getLevel() >= 7){
        this.mapInstance.setLevel(3);
      }

    },


    // ★ Map로드시 내위치 & 반경 원 표시 ★
    maploading(){
      //console.log("★ DB GET, 마커 생성★")
      // 지도에 표시할 원을 생성
      this.circle = new kakao.maps.Circle({
          center : new kakao.maps.LatLng(this.options.center.lat, this.options.center.lng),  // 원의 중심좌표 
          radius: 2500, // 미터 단위의 원의 반지름입니다  (반경 2.5km 도보 30분)
          strokeWeight: 5, // 선의 두께
          strokeColor: '#287BFF', // 선의 색깔
          strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
          strokeStyle: 'dashed', // 선의 스타일 
          // fillColor: '#287BFF', // 채우기 색깔
          // fillOpacity: 0.1  // 채우기 불투명도 입 
      }); 

      // 지도에 원을 표시합니다 
      this.circle.setMap(this.mapInstance); 

      // 내 위치 포인트 표시 (Overlay방식으로)
      //var gps_content = `'<div ><img src= ${require("@/images/myPosition.png")} style="width:30px; height:33px;"></div>'` // 내 위치 이미지
      var gps_content = '<div ><img src="https://ssl.pstatic.net/static/maps/m/pin_rd.png" alt="" style="width:20px; height:20px;"></div>' // 내 위치 이미지
      this.currentOverlay = new kakao.maps.CustomOverlay({
          position: new kakao.maps.LatLng(this.options.center.lat,this.options.center.lng), // 현재 내 위치 
          content: gps_content,
          map: this.mapInstance
      });
      this.currentOverlay.setMap(this.mapInstance);
    },
    // ★ WebtoApp (Preview Open) ★
    open_preview(marker){
      var markers = {}
      //var i = 0
      //App에서 처리하게 JSON타입으로 보내자.
      //key는 req의 id , value는 req의 data
      
      marker.forEach((a)=>{
        markers[a.id] = a.data()
    
        //i++
      })

      // eslint-disable-next-line
      Open_Preview.postMessage(JSON.stringify(markers))
    },

    // 기존 마커, 클러스터 제거
    remove(){ 
      /// (에러개선) 기존 마커가 없는 시점이면 Error발생으로 코드 멈춤
      try{
        this.clusterer.clear();
        this.markers.forEach((a)=>{
          a.setMap(null)
        })
        this.circle.setMap(null);
        this.currentOverlay.setMap(null);
      }catch(e){
        //console.log("Nothing to delete")
      }

    },

    // DB get함수 ( 마커 이벤트 리스너 포함 )
    DB_GET(){
      //  ★★★★★ Firebase Geohash를 사용해 반경(2.5 km)내 request DB만 GET해옴 ★★★★★
    const center = [this.options.center.lat, this.options.center.lng];
    const radiusInM = 1 * 2500; // 반경 2.5km 도보 30분거리
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];

    

    for (const b of bounds) {
      // request 갯수만큼 불러오는거 X 딱 4개 boundary만 불러옴 (무시가능)
      const q = db.collection(this.CollectionType).where("status", "==", "waiting") // waiting만 불러와야함
        .orderBy('hash')
        .startAt(b[0])
        .endAt(b[1]);

      promises.push(q.get());
    }

  // Collect all the query results together into a single list
    Promise.all(promises).then((snapshots) => {
      const matchingDocs = [];

      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.data().request_info.lat;
          const lng = doc.data().request_info.lng;

          const distanceInKm = distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            /// (기능추가) Case. 차단고객은 패스
            if(this.blockUserList.length === 0){
              matchingDocs.push(doc);
              //console.log("차단 고객 없음");
            }
            else{
              //console.log("차단 고객 있음");
              if(!this.blockUserList.includes(doc.data().client_info.uid)){
                matchingDocs.push(doc);
              }
            }
          }
        }
      }

      return matchingDocs;
    }).then((matchingDocs) => {

      this.requestdb = matchingDocs

      if(this.requestdb.length==0){
        // 내 주변 데이터가 없을 경우
        //console.log('데이터 없음');
        this.toast1_ui=true
        setTimeout(()=> {
          this.toast1_ui= false;
        }, 5500);

      }
      else{
        this.toast2_msg2 = this.requestdb.length;
        this.toast2_ui=true
        setTimeout(()=> {
          this.toast2_ui= false;
        }, 5500);

        //var i = 1
        // matchingDocs.forEach(()=>{
        //   console.log(i," 번쨰 Sorting결과",a.id)
        //   console.log(i," 번쨰Sorting결과",a.data().request_info.location)
        //   console.log(i," 번쨰Sorting결과",a.data().status)
        //   i = i+1
        // })
      }


      // ************** ★ :(개별)마커 click 이벤트핸들러" 지역함수 내에서 전역변&함수 컨트롤을 위한!★ ****************
      var Requestdata = this.requestdb; 

      // selected에 저장하는 함수
      var selected_save = (spot)=>{
        this.selected = spot
        // ★시간순으로 정렬하는 방법 !!★
        this.selected.sort(function(a, b) {
            a = a.data().time;
            b = b.data().time;
            return a>b ? -1 : a<b ? 1 : 0;
        });
        this.selected_summary.location = spot[0].data().request_info.location 
        this.selected_summary.address = spot[0].data().request_info.address
      }

      // select한 게시물 게시물 경과시간 계산 (Flutter에서 진행하게 변경함.)
      var get_timelast = ()=>{
        this.open_preview(this.selected);
        
        }

      // ************************************************

      // ★ WebtoApp (Preview Open) ★
      //var open_preview = this.open_preview;
      

      // 마커 클러스터러를 생성합니다  (clusterer)
      this.clusterer = new kakao.maps.MarkerClusterer({
          map: this.mapInstance, // 마커들을 클러스터로 관리하고 표시할 지도 객체 
          averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정 
          minLevel: 1, // 클러스터 할 최소 지도 레벨 
          disableClickZoom: true, // 클러스터 클릭시 zoom 사용 No (클러스터 클릭 함수 별도 정의할것)
      });

      // 마커 이미지 정의 

      var imageSrc = require("@/images/customPin.png"), 
      //var imageSrc = "@/images/customPin.png",( 이렇게 하면 이미지 안뜸 )
      imageSize = new kakao.maps.Size(42, 42), // 마커이미지의 크기입니다
      imageOption = {offset: new kakao.maps.Point(20, 43)}; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

      var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
      
      //markers
      this.markers = this.requestdb.map(function(position) {
          var marker = new kakao.maps.Marker({
              image: markerImage, // 마커이미지 설정 
              position : new kakao.maps.LatLng(position.data().request_info.lat, position.data().request_info.lng)
          });
      

      //★ (개별)마커 click 이벤트핸들러 ★
      kakao.maps.event.addListener(marker,'click', ()=>{

        // ★선택한 마커의 위경도값을 기준으로 , Mission데이터에서 상세 데이터를 가져오는 코드.★

          //  위경도 매칭 필터 (매칭된 데이터들이 spot Array에 들어가있음)
          // 위경도값 오차가 발생해서 소수점 9까지만 비교해서 동일한 데이터를 가져옴
        var spot = Requestdata.filter(function (spots) { return (Math.floor((spots.data().request_info.lat)*1000000000) == 
        Math.floor((marker.getPosition().Ma)*1000000000)) && 
        (Math.floor(spots.data().request_info.lng*10000000000)==Math.floor(marker.getPosition().La*10000000000))});
        

        // 지역함수안 spot 데이터를 (함수밖) 전역변수 data에 넣기 위한 우회방법임 (지역함수 밖에서는 전역data를 컨트롤 할 수 있으니)
        selected_save(spot)
        get_timelast()
        

        });
      return marker;
      });

      // 클러스터러에 마커들을 추가
      this.clusterer.addMarkers(this.markers);


      //★ (클러스터)마커 click 이벤트핸들러 ★
      kakao.maps.event.addListener( this.clusterer, 'clusterclick', (cluster)=> {
          

          var clusterzoom =0

          // 클릭시 zoom level 4이상 이면 zoom level -2 
          if(this.mapInstance.getLevel() >= 4){
            // 중심 이동
            this.mapInstance.setCenter(new kakao.maps.LatLng(cluster.getCenter().Ma, cluster.getCenter().La));
            // Double Zoom
            // console.log('Double Zoom')
            // console.log("변경 전 Zoom Level:",this.mapInstance.getLevel())
            clusterzoom = this.mapInstance.getLevel() -2
            this.mapInstance.setLevel(clusterzoom);
            // console.log("변경 후  Zoom Level:",this.mapInstance.getLevel())
        
            

          }

          // 클릭시 zoom level 4이하 이면 zoom level -1
          else if(this.mapInstance.getLevel() > 2 && this.mapInstance.getLevel() < 4 ){
            // 중심 이동
            this.mapInstance.setCenter(new kakao.maps.LatLng(cluster.getCenter().Ma, cluster.getCenter().La));
            // Single Zoom
            // console.log('Single Zoom')
            // console.log("변경 전 Zoom Level:",this.mapInstance.getLevel())
            clusterzoom = this.mapInstance.getLevel() -1
            this.mapInstance.setLevel(clusterzoom);
            // console.log("변경 후 Zoom Level:",this.mapInstance.getLevel())
          }


          // zoom 2이하면 좌표값을 반환 & 위에 위경도 매칭필터 그대로 사용 
          else{
            //console.log("클러스터 좌표:",cluster.getCenter() );

            // console.log('클러스터 바로 오픈')
              //  위경도 매칭 필터 (매칭된 데이터들이 spot Array에 들어가있음)
            var spot = Requestdata.filter(function (spots) { return (Math.floor((spots.data().request_info.lat)*10000000000) == 
            Math.floor((cluster.getCenter().Ma)*10000000000)) && 
            (Math.floor(spots.data().request_info.lng*10000000000)==Math.floor(cluster.getCenter().La*10000000000))});
            console.log("선택된 클러스터",spot)

            selected_save(spot)
            get_timelast()
          }

      });
        

    })

    }, //end DB_GET함수

    /// (에러개선)indext.html파일에 환경변수 미사용을 위해 KakaoHome에서 넣어준다.
    loadScript() {
      console.log("실행")
      const script = document.createElement("script");
      script.type="text/javascript";
      script.src =
        "https://dapi.kakao.com/v2/maps/sdk.js?appkey=73c00a61d9ef7c9d832f91190c37f6f9&libraries=services,clusterer,drawing"; 
      script.onload = () => window.kakao.maps.load(this.loadMap); // 스크립트 로드가 끝나면 지도를 실행될 준비가 되어 있다면 지도가 실행되도록 구현

      document.head.appendChild(script); // html>head 안에 스크립트 소스를 추가
    },

  },// End Method
  beforeMount(){
    // ApptoWeb GPS정보 전달 및 Webview Mount시 동작코드
    window["KakaoHome"] = {
      component: this,
      mygps: (position,postCollection,blockList) => this.mygps(position,postCollection,blockList),
    },
    // ApptoWeb refresh 코드
    window["KakaoHome2"] = {
      component: this,
      refresh: (position,postCollection,blockList) => this.refresh(position,postCollection,blockList),
    }

  },// End BeforeMount
  mounted(){
    if (window.kakao && window.kakao.maps) {
      //skip
    } else {
      // 없다면 카카오 스크립트 추가 후 맵 실행
      this.loadScript();
    }


  // ★ 라이브러리를 Index.html에 정의했기에 vue특성상 전역변수 window를 써줘야 동작함 ★
  kakao = kakao || window.kakao;
  //지도를 담을 영역의 DOM 레퍼런스
  var container = this.$refs.map; 

  // 초기 option값
  const { center, level } = this.options

  // ★ 지도 생성 및 객체 리턴 ★
  this.mapInstance = new kakao.maps.Map(container, {
    center: new kakao.maps.LatLng(center.lat, center.lng),
    level,
  }); 

  // Pinch zoom level변경 감지 이벤트 리스너 추가
  kakao.maps.event.addListener(this.mapInstance, 'zoom_changed', ()=>{        
    // 지도의 현재 레벨을 얻어옵니다
    var level = this.mapInstance.getLevel();
    if(level >= 7){
      this.toast3_ui=true
      setTimeout(()=> {
        this.toast3_ui= false;
      }, 5500);
    }
  });


  },// End Mounted
  
}
</script>

<style lang="scss">
@import "@/assets/common.scss";
.kmap{
  width: 100%;
  height: 100vh;
}


.progress{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 50;
}

.debugMarkbottom{
  position: fixed;
  left: 5px;
  bottom: 10px;
  z-index: 10;
  padding: 10px;
  color : black;
}



</style>