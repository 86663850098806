import { render, staticRenderFns } from "./KakaoHome.vue?vue&type=template&id=3e61ecec&"
import script from "./KakaoHome.vue?vue&type=script&lang=js&"
export * from "./KakaoHome.vue?vue&type=script&lang=js&"
import style0 from "./KakaoHome.vue?vue&type=style&index=0&id=3e61ecec&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports