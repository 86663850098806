<template>
  <div class="toast_msg" v-if="toast_ui== true">
		<div class="content">{{toast_msg}}</div>
		<div class="content">{{toast_msg2}}</div>
  </div>
</template>

<script>
export default {
	name: "MyToast2",
	props:{
    toast_msg : String,
		toast_msg2 : String,
    toast_ui : Boolean,
	},
	data() {
		return{
		}
	},

}
</script>

<style lang="scss">
@import "@/assets/common.scss";
.toast_msg{
  position: fixed;
  left: 50%;
  top: 85%;
  overflow-wrap: normal;
  transform: translate(-50%,-50%);
  width: fit-content;
  max-width: 95vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: rgb(46, 46, 46);
  z-index: 100;
  padding: 8px 8px;
  border-radius: 18px;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);

  padding: 10px;
  animation: fadeout 3.5s;
  animation-delay: 2s;
  -webkit-animation: fadeout 3.5s; /* Safari and Chrome */
  -webkit-animation-delay: 2s;
  animation-fill-mode: forwards;
}
.toast_msg .content{
	color: $--white;
  padding: 2px 5px;
}

// Fadeout 애니메이션

@keyframes fadeout {
    from {
			opacity: 1;
    }
    to {
			opacity: 0;
    }
}
@-webkit-keyframes fadeout { /* Safari and Chrome */
    from {
			opacity: 1;
    }
    to {
			opacity: 0;
    }
}

</style>