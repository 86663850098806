import KakaoHome from "./components/KakaoHome.vue";
import NewReq from "./components/NewReq.vue";
import EditReq from "./components/EditReq.vue";
import PostAlarmAdmin from "./components/postAlarmAdmin.vue";
import TestPage from "./components/test.vue";
import walkthrough from "./components/walkThrough.vue";


const routes = [
  {
    path: "/",
    component: KakaoHome,
  },
  {
    path: "/walkthrough",
    component: walkthrough,
  },
  {
    path: "/kakaohome",
    component: KakaoHome,
  },
  {
    path: "/newreq",
    component: NewReq,
  },
  {
    path: "/editreq",
    component: EditReq,
  },
  {
    path: "/postAlarmAdmin",
    component: PostAlarmAdmin,
  },
  {
    path: "/testpage",
    component: TestPage,
  }
];


export default routes;