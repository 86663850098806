import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";
import 'firebase/messaging';

const firebaseConfig = {
	apiKey: process.env.VUE_APP_API_KEY,
	authDomain: process.env.VUE_APP_AUTHDOMAIN,
	projectId: process.env.VUE_APP_PROJECTID,
	storageBucket: process.env.VUE_APP_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
	appId: process.env.VUE_APP_APPID
  };

const Maindb = firebase.initializeApp(firebaseConfig);

// const는 반드시  firebase.initializeApp(firebaseConfig); 이후에 정의해아함!!
const db = Maindb.firestore();
const storage = Maindb.storage();
const admin = firebase.firestore;

export {db,storage,admin};