<template>
	<div class="testpage">

		<div class ="mid">

			<div :class = isActive(i) class= "selectbox" v-for="(item, i) in selectType" :key="i" @click="select(i)">
				<span class="iconify" :data-icon = item.icon style="color: #287bff;" data-width="25" data-height="25"></span>
				<div class="title">{{item.title}} </div>
			</div>

			<div class="selectbox" @click="manual()">
				<div class="title_manual">직접입력</div>
			</div>

		</div>


	</div>
</template>

<script>


export default {
	name : "testPage",
	data(){
		return {
			// 선택지 데이터 
			selectType :[
				{title : "대기 인원", icon: "bi:people-fill"},
				{title : "영업중?", icon: "bi:door-open-fill"},
				{title : "남은 좌석", icon: "ic:baseline-table-bar"},
				{title : "날씨", icon: "emojione:sun-behind-cloud"},
				{title : "물건 재고", icon: "noto:shopping-cart"},
				{title : "교통,주차", icon: "openmoji:police-car"},
			],

			// 요청사항 선택 or 직접입력
			reqtype:"",
      // 선택한 요청사항
      selected_reqs: [0],
			isManualInput : false,
		}
	},
	methods:{
		select(i){
			this.isManualInput = false

			// Case1. 기존에 선택한 값 O
			if(this.selected_reqs.includes(i)){
			this.selected_reqs = this.selected_reqs.filter((element) => element !== i) // 해당 값 제거
			}
			// Case1. 기존에 선택한 값 X
			else{
				// Case. 기존 1개 이하 선택상태 
				if(this.selected_reqs.length <= 1 ){
					this.selected_reqs.push(i)
				}
			}

			
		},
		manual(){
			console.log("직접 입력")
			this.isManualInput = true,
			this.selected_reqs =  [] // 직접 선택지 초기화
		},

		isActive(i){
			if( this.selected_reqs.includes(i) ){
				return "selected"
			}else{
				return ""
			}
		}
	},
	watch:{
		selected_reqs(val){
			console.log("변경됨", val)
		},
		isManualInput(val){
			if(val == true){
				console.log("★직접입력★")
			}else{
				console.log("★선택지 선택★")
			}
		}
	
	}
}
</script>

<style lang="scss">
@import "@/assets/common.scss";

//선택 X상태 
.selectbox{
	width: fit-content;
	white-space: nowrap;
	display: flex;
	align-items: center;
  justify-content: center;
	padding: 5px 13px 5px 13px;
  margin: 5px 10px;
	border: solid 1px #e9e9e9;
	border-radius: 25px;
}
.selectbox .title{
	padding-left: 10px;
}

// 선택된 상태
.selected{
	width: fit-content;
	white-space: nowrap;
	display: flex;
	align-items: center;
  justify-content: center;
	padding: 5px 13px 5px 13px;
  margin: 5px 10px;
	border: solid 1px #2196F3;
	border-radius: 25px;
}
.selected .title{
	padding-left: 10px;

	color: #2196F3;
	font-weight: bold;
}

</style>