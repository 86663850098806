<template>
  <div class="EditReq">

    <!-- progress circle(장소정보 GET전) -->
    <div class="beforeLoading"  v-if="beforeLoading == 'ing' " >
      <div class="overlay"></div>
      <div class="circle">
        <v-progress-circular indeterminate color= "#2196F3"></v-progress-circular>
      </div>
    </div>

    <!-- progress circle(등록 진행중) -->
    <div class="submit_progress"  v-if="submit_progress == 'ing' " >
      <div class="overlay"></div>
      <!-- circle -->
      <div class="circle">
        <v-progress-circular indeterminate color= "#287BFF"></v-progress-circular>
      </div>
    </div>

		
		<!-- stepper_require -->
		<div class="stepper_require">
			<div class="stepper_requirebox" id="place" @click="step_select()">
				<div :class="stepnumber_ui[0]" id="place">1</div>
				<div :class="steptitle_ui[0]" id="place">장소</div>
			</div>
			<v-divider></v-divider>
			<div class="stepper_requirebox" id="req" @click="step_select()">
				<div :class="stepnumber_ui[1]" id="req">2</div>
				<div :class="steptitle_ui[1]" id="req">내용</div>
			</div>
			<v-divider></v-divider>
			<div class="stepper_requirebox" id="commission" @click="step_select()">
				<div :class="stepnumber_ui[2]" id="commission">3</div>
				<div :class="steptitle_ui[2]" id="commission">리워드</div>
			</div>
		</div> <!--end stepper_require-->

    <!-- container -->
		<div class="new_container">

				<!-- 장소 검색창 -->
			<div id="search_wrap" class="bg_white" v-if="now_step=='place'">
				<!-- 장소 입력창 -->

        <!-- (에러개선) iOS keyboard 우측 상단 'done'버튼 클릭 인식
        'blur' Event는 Keyborad가 hide될때 call되는데 'done'버튼 클릭시
          keyboard가 hide되는 것에서 착안. -->
				<div class="searchbox">
					<div>
						<input class="search" type="text" placeholder=" 장소 검색" v-model= "searchvalue"
						@keyup.enter="searchPlaces()" @blur="searchPlaces()" id="keyword" size="15" autocomplete="off" autofocus> 
					</div>
				</div>

				<!-- 검색 결과창 -->
				<div class="results" >
          <div class="resultsExist" v-if="isSearchValueExist == true">
            <!-- 검색 결과 클릭시 해당 위치 lat(y) lng(x) 반환 -->
            <div class="places" v-for="rs in search.results" :key="rs.id" @click="showPlace(rs)">
              <h4>{{rs.place_name}}</h4>
              <div class="addr">{{rs.road_address_name}}</div>
            </div>
          </div>  
          <div class="noResults" v-if="isSearchValueExist == false">
            <div>검색 결과가 없습니다.</div>
          </div>
				</div>
			</div>

			<!-- 지도부분 -->
			<div :class="mapsize" ref="map"></div>
		</div>

		<!-- 요청내용 modal -->
		<div class="request_modal" v-if="now_step=='req'" ref="inputboxes">
			<div class="dummybox"></div>
			<div class="top_2">
				<div class="title">무엇이 궁금하신가요?</div>
				<div class="subtitle">( 최대 2개 선택 가능 )</div>
			</div>
			<!-- mid_2 -->

			<div class="mid_2">
	
        
        <div :class = isActive(i) class= "selectbox" v-for="(item, i) in selectType" :key="i" @click="select(i)">
          <span class="iconify" :data-icon = item.icon :style = "{'color': item.color }" data-width="25" data-height="25"></span>
          <div class="title">{{item.title}} </div>
        </div>

        <div :class = isManualActive() class="selectboxManual" @click="openmanul()">
          <div class="title_manual">직접입력</div>
        </div>

				<!-- 직접입력칸 -->
				<v-app>
					<v-form ref="form">
						<!-- 직접 입력시 선택한거 해제됨 -->
						<div class="request" v-if="isManualInput == true" > 
							<v-textarea outlined label="직접 입력" @input="handleInput" v-model="request" :rules="request_rule" counter maxlength="50"></v-textarea>
						</div>
					</v-form>
				</v-app>
			</div>

			<!-- bottom -->
			<div class="bottom">
				<div class="btn_nextstep" v-if="manual_filled == true || select_selected == true" @click="to_comstep()">다음</div>
			</div>
			<div class="dummybox"></div>
		</div>

		<!-- commission_modal -->
		<div class="commission_modal" v-if="now_step=='commission'">
			<!-- stepper 3. place -->
			<div class="box" >
				<!-- top_3 -->
				<div class="dummybox"></div>
				<div class="dummybox"></div>
				<div class="top_3">
					<div class="title">리워드를 입력해주세요!</div>
					<div class="subtitle">( 알려주신 분에게 보낼 포인트 )</div>
				</div>
				<!-- mid_3 -->
				<div class="mid_3">
          <v-app>
            <v-form ref="form" @submit.prevent>
              <div class="activebalance">
                <div style="margin-right:5px;">사용 가능 포인트 : {{active_balance.toLocaleString('ko-KR')}} P</div>
              </div>
              <div class="commission">
                <v-text-field
                  @keyup.enter="add_EditReq"
                  outlined
                  v-model="commission"
                  :rules="commission_rule"
                  required
                  type="number"
                  suffix="Point"
                ></v-text-field>
              </div>
            </v-form>
          </v-app>


				</div>
				<!-- bottom_3 -->
				<div class="bottom">
					<div class="btn_nextstep" @click="add_EditReq()">수정 완료</div>
				</div>
				<div class="dummybox"></div>
				<div class="dummybox"></div>

			</div><!--end stepper 3-->
		</div>

  </div>
</template>

<script>
// import {db,admin,Admin_db} from './firebaseConfig';
//import {db} from './firebaseConfig';
import "firebase/firestore";
import jQuery from 'jquery';
import {geohashForLocation} from "geofire-common";
  // ★ 라이브러리를 Index.html에 정의했기에 vue특성상 전역변수 window를 써줘야 동작함 ★
let kakao = window.kakao;

export default {
	name: 'EditReq',
	data() {
		return{
      // 제출 진행중
      submit_progress:"",
      // Info UI
      balanceinfo_ui : false,
			// ★★★ stepper 관련 데이터 ★★★

      // 현재 선택된 Stepper UI data
      stepnumber_ui :["stepnumber","active_stepnumber","stepnumber"],
      steptitle_ui :["steptitle","active_steptitle","steptitle"],
			// 현재 선택된 Stepper
      now_step:"req",

			// ★★★ 데이터 선택 유무 ★★★
      // 위치선택 유무 UI
      require_position: false,
			positionselected : true,

			// ★★★ 지도 관련 데이터 ★★★
			// mapsize변화 UI데이터
			mapsize:"map_small",
      mapInstance:null,
      search: {
        keyword: null,
        pgn: null,
        results: [],
      },
      // 초기 map옵션값(option)
      options:{
        center: {
          lat: 37.5759689663327,
          lng: 126.976861018866,
        },
        level: 3,
      },
      // 선택한 장소 
      selectplace:{
        name:"",
        address:"",
        lat:0,
        lng:0,
      },
      curselect:{
        lat:0,
        lng:0,
      },
      markers : [], // 테스트
      overlays: [],
      // 검색결과
      searchvalue:"",
      // 검색결과 존재여부
      isSearchValueExist:true,

			// ★★★ 요청내용 관련 데이터 ★★★
      // 선택지 데이터 
			selectType :[
				{title : "대기 인원", icon: "bi:people-fill", color : "#287bff"},
				{title : "영업중?", icon: "bi:door-open-fill", color : "#81613c"},
				{title : "남은 좌석", icon: "ic:baseline-table-bar", color : "#81613c"},
				{title : "날씨", icon: "emojione:sun-behind-cloud", color : "#81613c"},
				{title : "물건 재고", icon: "noto:shopping-cart", color : "#81613c"},
				{title : "교통,주차", icon: "openmoji:police-car", color : ""},
			],
			isManualInput: false, // 직접입력창 UI 활성화 
      // 요청사항 선택 or 직접입력
      reqtype:"",
      // 선택한 요청사항
      selected_reqs: [],
			// 직접 입력한 내용
			request:"",
      request_rule: [
        v => !/[@#$&*_|:{}]/.test(v) || '요청사항에 특수문자를 사용할 수 없습니다.',
        v => v.length <= 50 || '글자수 초과'
      ],

			// 직접 입력했음?(내용 입력여부)
			manual_filled:false,
			// 선택했음? (선택지 선택여부)
			select_selected:false,

			// ★★★ 수수료 관련 데이터 ★★★
      commission: null,
      // Input Rule관리
      commission_rule: [
        v => !!v || '수수료(원)은 필수 입력사항입니다',
        v => v <= 10000 || '수수료(원)는 최대 1만원까지만 가능합니다.',
        v => v > 0 || '수수료(원)는 필수 입력사항입니다.',
        v => v%10 == 0 || '100원 단위로 입력이 가능합니다.',
        v => v%100 == 0 || '100원 단위로 입력이 가능합니다.',
        v => v <= this.active_balance || '잔고가 부족합니다.',
      ],
      // 사용가능 잔고(activebalance)
      active_balance:0,
			tesval : false
		}
	},
	//End data


	methods:{
    /// ★선택지 토글 함수★
    select(i){
			this.isManualInput = false // 직접입력창 UI 창 비활성화
      this.request = "" // 직접 입력 내용 초기화

			// Case1. 기존에 선택한 값 O
			if(this.selected_reqs.includes(i)){
			this.selected_reqs = this.selected_reqs.filter((element) => element !== i) // 해당 값 제거
			}
			// Case1. 기존에 선택한 값 X
			else{
				// Case. 기존 1개 이하 선택상태 
				if(this.selected_reqs.length <= 1 ){
					this.selected_reqs.push(i)
				}
			}

			
		},
    /// ★직접입력 선택지 토글 함수★
		manual(){
			//console.log("직접 입력")
			this.isManualInput = true,
			this.selected_reqs =  [] // 직접 선택지 초기화
		},
    /// ★ 선택지 UI 토글 함수★
		isActive(i){
			if( this.selected_reqs.includes(i) ){
				return "selected"
			}else{
				return ""
			}
		},
    /// ★ 직접입력 UI 토글 함수★
		isManualActive(){
      if(this.isManualInput){
        return "selectedManual"
      }else{
        return ""
      }
		},


    // APPtoWeb 요청함수 (App의 GPS정보를 받아오는 함수)
    mygps(position){

      //console.log("★ APP GPS받아옴 ★")

      var changeposition= ()=> {
        return new Promise((resolve) => {
          this.options.center.lat = Number(JSON.parse(position)[0])
          this.options.center.lng = Number(JSON.parse(position)[1])
          resolve()

        })
      }
      var webview_mounted = () => {
        changeposition().then(() =>{
          // setTimeout으로 안하고 그냥 center를 잡으니 좀 이상함..그리고 오히려 setTimeout하니 더 빠르게 로딩됨.
          setTimeout(()=>{
            this.mapInstance.setCenter(new kakao.maps.LatLng(this.options.center.lat, this.options.center.lng));
          }, 100);

        })
      }
      webview_mounted()

    },		
    // APPtoWeb 요청함수 (App의 로그인 고객 정보를 받아오는 함수)
    user_activebalance(val){
      this.active_balance = Number(val)

    },		

  
    // stepper 처리함수 
    step_select(){
      this.now_step = event.target.id
    },
    // 이전 검색 마커& overlay버튼 삭제
    prv_delete(){
      var deleteMarkers = ()=> {
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }              
      }
      var deleteOverlays = ()=> {
        for (var i = 0; i < this.overlays.length; i++) {
            this.overlays[i].setMap(null);
        }              
      }
      deleteMarkers()
      deleteOverlays()
    },
    // 검색결과 enter시 호출함수
    searchPlaces() {
      const keyword =  this.searchvalue.trim();
      if(keyword.length ===0){
        return;
      }

      const ps = new window.kakao.maps.services.Places();  //장소 객체생성

      // 장소검색 객체를 통해 키워드로 장소검색을 요청합니다
      ps.keywordSearch( keyword, (data)=>{
        this.search.keyword = keyword;
        // this.search.pgn = pgn;
        this.search.results = data;

        /// (UX 개선 추가) 검색결과 없으면 '검색 결과가 없습니다' 표시
        if(this.search.results.length ==0){
          this.isSearchValueExist = false;
        }else{
          this.isSearchValueExist = true;
        }
      }); 
    },

    // 추가로 해당 위치로 지도가 이동하게 하기위해서는 options값을 변경해주면 된다 !
    async showPlace(place) {
      this.prv_delete()

      var placesave = ()=>{
        return new Promise((resolve)=>{
          this.selectplace.name = place.place_name; // 선택된 장소 이름
          this.selectplace.address = place.road_address_name; // 선택된 장소의 주소
          this.selectplace.lat = place.y; //선택된 lat
          this.selectplace.lng = place.x; //선택된 lng
          // 선택한 장소의 위경도 값 저장
          resolve(this.curselect = { lat: place.y, lng: place.x })
        })
      }
      
      await placesave().then(()=>{
        // 선택한 장소로 center 이동 보여줌
        this.mapInstance.setCenter(new kakao.maps.LatLng(this.curselect.lat, this.curselect.lng));

        // 마커 이미지 정의 

        var imageSrc = require("@/images/customPin.png"), 
        //var imageSrc = "@/images/customPin.png",( 이렇게 하면 이미지 안뜸 )
        imageSize = new kakao.maps.Size(45, 45), // 마커이미지의 크기입니다
        imageOption = {offset: new kakao.maps.Point(22, 46)}; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

        var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);

        //  해당 위치에 마커 추가
        var marker = new kakao.maps.Marker({
        map: this.mapInstance,
        image: markerImage, // 마커이미지 설정 
        position: new kakao.maps.LatLng(this.curselect.lat, this.curselect.lng)
        });
        marker.setMap(this.mapInstance);
        this.markers.push(marker);

        // 선택 포인트 표시 (Overlay방식으로)
        var combtn = '<div class="btn_selectplace">장소 선택하기</div>' // 내 위치 이미지
        var currentOverlay = new kakao.maps.CustomOverlay({
            position: new kakao.maps.LatLng(this.curselect.lat,this.curselect.lng), // 현재 내 위치 
            content: combtn,
            map: this.mapInstance
        });
        this.overlays.push(currentOverlay);
        currentOverlay.setMap(this.mapInstance);
      })
    },

		//★ "완료" 버튼 클릭
    async map_complete(){

      var mapsizechange = ()=>{
        return new Promise((resolve)=>{
          resolve(this.mapsize="map_small")
        })
      }
	
			await mapsizechange().then(()=>{
				this.mapInstance.relayout();// ★이거 안하면 center 안맞춰짐★
				// 1st.선택한 장소로 center 이동 보여줌
				this.mapInstance.setCenter(new kakao.maps.LatLng(this.curselect.lat, this.curselect.lng));
				// 2nd. stepper 이동
				this.to_reqstep()
				
			})
    },

    //2.요청내용 단계로 이동
    to_reqstep(){
      // 1st. 위치 선택 여부 확인 
      if(this.selectplace.lat!=0 && this.selectplace.lng!=0){
        // 위치 선택 완료
        this.positionselected = true
        this.now_step = "req"
        this.stepnumber_ui = ["stepnumber","active_stepnumber","stepnumber"]
        this.steptitle_ui = ["steptitle","active_steptitle","steptitle"]    
      }else{
        // 위치 선택 미완료 (에러 메시지 추가)
        this.positionselected = false
        this.require_position = true
      }
    }, 
    // 수수료 단계로 이동
    to_comstep(){
			this.now_step = "commission"
			this.stepnumber_ui = ["stepnumber","stepnumber","active_stepnumber"]
			this.steptitle_ui = ["steptitle","steptitle","active_steptitle"]    

    },

		// 직접 입력창 오픈
		openmanul(){
      this.selected_reqs =  [] // 직접 선택지 초기화
			//이미 2개 다른거 입력된 상태일때
			if(this.selected_reqs.length == 2){
				this.isManualInput = true
				this.manual_filled = false
				this.select_selected = false
        setTimeout(()=> {
          // scroll 최하단
          this.autoscroll()
        }, 10);

			}
			else{
				this.isManualInput = true
				this.manual_filled = false
				this.select_selected = false
        setTimeout(()=> {
          // scroll 최하단
          this.autoscroll()
        }, 10);
			}


		},
    // ★ Request DB Update요청 (App에서 할거임) ★
    add_EditReq(){
			//console.log("★ 제출 ★ ")
      //this.submit_progress = "ing" // 등록 진행중 circle표시

      // 입력 양식 유효성 검사 (true or false)
      var validate = this.$refs.form.validate();

      // 위치 선택 & 요청내용  &  수수료 & 입력 유효성 검사 모두 통과시
      if( validate==true && this.positionselected == true && (this.manual_filled == true == true || this.select_selected == true) ){

          // request collection document field (저장될 데이터)
          var edit_request = {
            // time : new Date(),
            // starttime : new Date(),
            start_y : new Date().getFullYear(),
            start_m : new Date().getMonth()+1,
            start_d : new Date().getDate(),
            status : 'waiting',
            // request 정보
            request_info :{
              location: this.selectplace.name,
              address: this.selectplace.address,
              lat: Number(this.selectplace.lat),
              lng: Number(this.selectplace.lng),
              reqtype: this.reqtype,// (요청사항)선택 or 직접입력
              select_content: this.selected_reqs, // 선택한 요청사항
              content: this.request, //(요청사항)직접 입력한 내용
              commission: this.commission,
            },
            //user(server) 정보
            user_info :{
              uid:"",
              name:"",
            },
            // Client가 Sto할경우, Stop된 user history
            history: [""],
            // firebase geofire 를 쓰기위한 hash
            hash : geohashForLocation([Number(this.selectplace.lat), Number(this.selectplace.lng)]),
          }


        // ★ App에 Request정보 전달 및 등록 요청 ★

        // eslint-disable-next-line
        Add_Edit.postMessage(JSON.stringify(edit_request))


        //   // // ★ Admin Analytic DB ★
        //   // var new_request_admin = {
        //   //   starttime : new Date(),
        //   //   start_y : new Date().getFullYear(),
        //   //   start_m : new Date().getMonth()+1,
        //   //   start_d : new Date().getDate(),
        //   //   // request 정보
        //   //   request_info :{
        //   //     location: this.selectplace.name,
        //   //     address: this.selectplace.address,
        //   //     lat: Number(this.selectplace.lat),
        //   //     lng: Number(this.selectplace.lng),
        //   //     reqtype: this.reqtype,// (요청사항)선택 or 직접입력
        //   //     select_content: this.selected_reqs, // 선택한 요청사항
        //   //     content: this.request, //(요청사항)직접 입력한 내용
        //   //     commission: this.commission,
        //   //   },
        //   // }
        //   // //오늘 날짜 doc에 저장220602
        //   // Admin_db.collection('new_request').add(new_request_admin).then(()=>{
        //   //   console.log('Admin DB 저장완료');
        //   // }).catch((err)=>{
        //   //   console.log(err)
        //   // })

        // }
        // })


      }
      // 작성내용 미흡시,
      else if(this.positionselected != true){
        // PWA device 진동 (Safari 동작X)
        //window.navigator.vibrate(200);
        this.submit_progress = "" // 등록 진행중 circle표시삭제
        this.now_step = "place" // step1 페이지로 이동

      }
      else if((this.manual_filled == true || this.select_selected == true) != true){
        // PWA device 진동 (Safari 동작X)
        //window.navigator.vibrate(200);
        this.submit_progress = "" // 등록 진행중 circle표시삭제
        this.now_step = "req" // step2 페이지로 이동
      }else{
        this.submit_progress = "" // 등록 진행중 circle표시삭제
      }
    },
    // scroll
    autoscroll(){
      var inputboxes = this.$refs.inputboxes
      //console.log("오토!",inputboxes)
      if(inputboxes === undefined ){
        //
      }else{
        inputboxes.scrollTo({ top: inputboxes.scrollHeight });
      }
    },
		// ★ APPtoWeb 요청함수 (수정할 req 데이터를 가져옴)★ 
		get_existed_db(val){
			//console.log("기존 DB(web): ")
			//console.log(val.request_db)
			//console.log("선택한 req id:",val.request_id)

			// step1. App에서 수정할 req의 정보를 받아옴
			this.options.center = {
				lat : val.request_db.request_info.lat,
				lng : val.request_db.request_info.lng
			}

			this.selectplace = {
				name: val.request_db.request_info.location,
        address: val.request_db.request_info.address,
        lat: val.request_db.request_info.lat,
        lng: val.request_db.request_info.lng
			}

			this.curselect = {
				lat: val.request_db.request_info.lat,
        lng: val.request_db.request_info.lng
			}

			this.reqtype = val.request_db.request_info.reqtype
			this.selected_reqs =  val.request_db.request_info.select_content
			this.request = val.request_db.request_info.content
			this.commission = val.request_db.request_info.commission

      // step2. ★ Edit Page Load시 UI 제어를 위한 코드 ★
      // 기존 Request가 직접입력이였을 경우?
      if(this.reqtype == "manual"){
        //console.log("A타입")
        this.isManualInput = true // 직접입력창 Open
        this.manual_filled = true 
      }else{
        //console.log("B타입"+ this.reqtype)
        this.isManualInput = false // 직접입력창 close
        this.manual_filled = false
      }
      
			// step3. showPlace (Center잡고, Marker표시)
			// 선택한 장소로 center 이동 보여줌
			this.mapInstance.setCenter(new kakao.maps.LatLng(this.curselect.lat, this.curselect.lng));

    // 마커 이미지 정의 

      var imageSrc = require("@/images/customPin.png"), 
      //var imageSrc = "@/images/customPin.png",( 이렇게 하면 이미지 안뜸 )
      imageSize = new kakao.maps.Size(45, 45), // 마커이미지의 크기입니다
      imageOption = {offset: new kakao.maps.Point(22, 46)}; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

      var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);

			//  해당 위치에 마커 추가
			var marker = new kakao.maps.Marker({
			map: this.mapInstance,
      image: markerImage, // 마커이미지 설정 
			position: new kakao.maps.LatLng(this.curselect.lat, this.curselect.lng)
			});
			marker.setMap(this.mapInstance);
			this.markers.push(marker);

			// 선택 포인트 표시 (Overlay방식으로)
			var combtn = '<div class="btn_selectplace"">장소 선택하기</div>' // 내 위치 이미지
			var currentOverlay = new kakao.maps.CustomOverlay({
					position: new kakao.maps.LatLng(this.curselect.lat,this.curselect.lng), // 현재 내 위치 
					content: combtn,
					map: this.mapInstance
			});
			this.overlays.push(currentOverlay);
			currentOverlay.setMap(this.mapInstance);
			var $ = jQuery
			$('.btn_selectplace').css("visibility", "hidden") // 장소 선택버튼 안보이게


		},
    handleInput() {
      // 입력값을 처리하는 로직
      this.request = this.filteredRequest;
    },
	},
	//End methods
	//End methods
  computed: {
    filteredRequest() {
      // 특수 문자를 제거하거나 다른 처리를 수행할 수 있습니다.
      return this.request.replace(/[@#$&*_|:{}]/g, '');
    },
  },

  mounted() {
    // ★ 라이브러리를 Index.html에 정의했기에 vue특성상 전역변수 window를 써줘야 동작함 ★
    kakao = kakao || window.kakao;

    //지도를 담을 영역의 DOM 레퍼런스
    var container = this.$refs.map; 

		// 초기 option값
		const { center, level } = this.options

		// ★ 지도 생성 및 객체 리턴 ★
		this.mapInstance = new kakao.maps.Map(container, {
			center: new kakao.maps.LatLng(center.lat, center.lng),
			level,
		}); 


    var $ = jQuery
    // 장소 선택 버튼
    $('.new_container').on("click", ".btn_selectplace",()=>{
      this.map_complete()
    })

  }, 
	//End mounted
  beforeMount(){
    //ApptoWeb 고객 activebalance 정보
    window["EditReq2"] = {
      component: this,
      user_activebalance: (val) => this.user_activebalance(val),
    }

    // ApptoWeb 기존 Req정보 
    window["EditReq"] = {
      component: this,
      get_existed_db: (val) => this.get_existed_db(val),
    }
    

  },// End BeforeMount
  watch:{
    // ★ 무슨 스탭? ★
    now_step(val){
      var $ = jQuery
      // stepper UI control
      if(val== "place"){
        $('.btn_selectplace').css("visibility", "visible") //  '장소선택' Show

        this.stepnumber_ui = ["active_stepnumber","stepnumber","stepnumber"]
        this.steptitle_ui = ["active_steptitle","steptitle","steptitle"]
				this.mapsize="map_full"
				setTimeout(() => {
					this.mapInstance.relayout()// ★이거 안하면 center 안맞춰짐★
				}, 100);
				
      }
      else if(val == "req"){
        $('.btn_selectplace').css("visibility", "hidden")  // '장소선택' Hide
        this.stepnumber_ui = ["stepnumber","active_stepnumber","stepnumber"]
        this.steptitle_ui = ["steptitle","active_steptitle","steptitle"]
				this.mapsize="map_small"  
				this.mapInstance.relayout()// ★이거 안하면 center 안맞춰짐★

      }
      else if(val == "commission"){
        $('.btn_selectplace').css("visibility", "hidden")  // '장소선택' Hide'
        this.stepnumber_ui = ["stepnumber","stepnumber","active_stepnumber"]
        this.steptitle_ui = ["steptitle","steptitle","active_steptitle"]
				this.mapsize="map_small"  
				this.mapInstance.relayout()// ★이거 안하면 center 안맞춰짐★
      }
    },
    searchvalue(val){
      var reg = /[@#$%^&*()_+|:{}]/
      if( reg.test(val) ){
        this.searchvalue = this.searchvalue.slice(0,-1); // 특수문자입력시 입력하면 지워버림
      }
    },
		// 선택지 선택 유무 확인
		selected_reqs(val){
      
			// 아무것도 선택안된 경우
			if(val.length ==0){
        //console.log("★ 선택지 0개 ★")
				this.select_selected = false
				this.reqtype = ""
				this.manualinput = 'close'
        setTimeout(()=> {
          // scroll 최하단
          this.autoscroll()
        }, 100);
			}

			// 1개 선택
			else if(val.length ==1){
				// 1개가 직접 선택이 아닌경우?
				if(val.indexOf(6) == -1){
          // 6번째가 직접입력이니 indexof 6임.
          //console.log("★ 선택지 1개 ★",val.indexOf(5))
					this.select_selected = true
					this.reqtype = "select"
					//console.log("B",val)
          setTimeout(()=> {
            // scroll 최하단
            this.autoscroll()
          }, 100);
				}
				// 선택된 1개가 직접입력인 경우?
				else{
          //console.log("★ 선택지 직접입력 ★")
					this.select_selected = false
					//console.log("A",val)
          setTimeout(()=> {
            // scroll 최하단
            this.autoscroll()
          }, 100);
				}
			}
			// 2개 선택
			else if(val.length ==2){

				// 직접입력을 2번째로 누른경우
				if(val.indexOf(6)==1){
          //console.log("★ 선택지 2번째  > 직접입력 ★")
					this.selected_reqs=[6]
					this.manualinput = 'open'
					this.select_selected = false
          setTimeout(()=> {
            // scroll 최하단
            this.autoscroll()
          }, 100);
				}
				// 직접입력을 처음으로 누르고 , 다른거 누른경우
				else if(val.indexOf(6)==0){
          //console.log("★ 선택지 직접입력 > 선택지 1개 ★")
					this.manualinput = 'close'
					val.shift()
					this.select_selected = true
          setTimeout(()=> {
            // scroll 최하단
            this.autoscroll()
          }, 100);
				}
				// 직접입력 안눌렀다면
				else if(val.indexOf(6)==-1){
          //console.log("★ 선택지 2개★")
					this.select_selected = true
					this.reqtype = "select"
          setTimeout(()=> {
            // scroll 최하단
            this.autoscroll()
          }, 100);
				}
			}
		},
    // 직접입력 입력 유무
    request (val) {
      if(val !=""){
        this.manual_filled = true
        this.reqtype = "manual"
        setTimeout(()=> {
          // scroll 최하단
          this.autoscroll()
        }, 100);
      }else{
        this.manual_filled = false
      }
    },
    
  },
	//End Watch


}
</script>


<style lang="scss">
@import "@/assets/common.scss";

// header Style
.header_EditReq{
  position: fixed;
  width: 100%;
  height: 45px;
  z-index: 2;
}
.header_EditReq .prev{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $--black;
}
.header_EditReq .prev .btn_prev{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_EditReq .prev .btn_prev .btn{
  margin-left: 15px;
}
.header_EditReq .prev .btn_prev .text{
  margin-left: 15px;
  font-weight: bold;
  line-height: 1 !important;
}
// stepper_require style
.stepper_require{
  position: fixed;
  //top: 50px;
  left: 0px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  //padding: 10px 15px;
}
.stepper_require .stepper_requirebox{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0 10px;
}
.stepper_require .stepper_requirebox .stepnumber{
  background-color: $--grey;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: $--white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepper_require .stepper_requirebox .active_stepnumber{
  background-color: $--primary;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: $--white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepper_require .stepper_requirebox .steptitle{
  color: $--secondary;
  padding: 3px;
  font-weight: bold;
  padding-left: 5px;
}
.stepper_require .stepper_requirebox .active_steptitle{
  color: $--black;
  padding: 3px;
  font-weight: bold;
  padding-left: 5px;
}
.new_container{
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  height: calc(100vh - 60px);
}
// 지도부분 style
.new_container .map_full{
  position: fixed;
  left: 0;
  top: 55px;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: antiquewhite;
	border-radius: 10px 10px 0 0;
}
.new_container .map_small{
  position: fixed;
  left: 0;
  top: 55px;
  width: 100%;
  height: calc(100vh - 60px - 40vh);
  background-color: antiquewhite;
	border-radius: 10px 10px 0 0;
}
.request_modal::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera*/
}
// ★요청내용 modal ★
.request_modal{
  position: fixed;
  z-index: 11; // cf, App.vue의 footer가 z-index 10으로 설정함
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50vh; //★★★★
  background-color: $--white;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px 15px 0px 0px;
  overflow-y: scroll;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: space-between;
}

// steper2 top style
.request_modal .top_2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	margin-top: 15px;
}
.request_modal .top_2 .title{
  font-weight: bold;
  font-size: 16px;
}
.request_modal .top_2 .subtitle{
  margin-top: 5px;
  color: $--secondary;
  font-size:14px;
}

// steper2 min style
.request_modal .mid_2{
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
// steper2 min style
.EditReq .request_modal .mid_2{
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
//선택 X상태 (선택지)
.EditReq .selectbox{
	width: fit-content;
	white-space: nowrap;
	display: flex;
	align-items: center;
  justify-content: center;
	padding: 5px 13px 5px 13px;
  margin: 5px 8px;
	border: solid 1px #e9e9e9;
	border-radius: 25px;
}
.EditReq .selectbox .title{
	padding-left: 10px;
}

.EditReq .selectbox .title_manual{
	padding: 3px;
}
// 선택된 상태 (선택지)
.EditReq .selected{
	width: fit-content;
	white-space: nowrap;
	display: flex;
	align-items: center;
  justify-content: center;
	padding: 5px 13px 5px 13px;
  margin: 5px 10px;
	border: solid 1px #2196F3;
	border-radius: 25px;
}
.EditReq .selected .title{
	padding-left: 10px;
	color: #2196F3;
	font-weight: normal;
}

//선택 X상태 (직접입력)
.EditReq .selectboxManual{
	width: fit-content;
	white-space: nowrap;
	display: flex;
	align-items: center;
  justify-content: center;
	padding: 5px 13px 5px 13px;
  margin: 5px 8px;
	border: solid 1px #e9e9e9;
	border-radius: 25px;
}

// 선택된 상태 (직접입력)
.EditReq .selectedManual{
	width: fit-content;
	white-space: nowrap;
	display: flex;
	align-items: center;
  justify-content: center;
	padding: 5px 13px 5px 13px;
  margin: 5px 10px;
	border: solid 1px #2196F3;
	border-radius: 25px;
}
.EditReq .selectedManual .title_manual{
	color: #2196F3;
	font-weight: normal;
}



// 직접 입력 부분
.request_modal .mid_2 .request{
  width: 80vw;
  //height: 30vh;
}
.v-textarea textarea{
  line-height: 1.5rem !important;
  max-height: 10vh !important;
}

// toggle 버튼 초기화
.v-application--wrap{
	min-height: 1vh !important;
}
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default{
  height: fit-content !important;
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: $--white !important;
}
.theme--light.v-btn {
    color: #287BFF; // 버튼 클릭시 색깔
}
.request_modal .bottom{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.request_modal .btn_nextstep{
  background-color: $--primary;
  width: 60%;
  padding: 7px 0 7px 0;
  color: $--white;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 15px;
}

// ★ 수수료 modal ★
.commission_modal::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera*/
}
.commission_modal{
  position: fixed;
  z-index: 11; // cf, App.vue의 footer가 z-index 10으로 설정함
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50vh; //★★★★
  background-color: $--white;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px 15px 0px 0px;
  overflow-y: scroll;

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: center;
	justify-content: space-between;
}
.commission_modal .box{
  width: 100vw;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
// steper3 top style
.commission_modal .box .top_3{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.commission_modal .box .top_3 .title{
  font-weight: bold;
}
.commission_modal .box .top_3 .subtitle{
  margin-top: 5px;
  color: $--secondary;
  font-size:14px;
}
// steper3 mid style
.commission_modal .mid_3{
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.commission_modal .mid_3 .activebalance{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: $--secondary;
  width: 80vw;
}
.commission_modal .mid_3 .commission{
  width: 80vw;
}
.commission_modal .btn_nextstep{
  background-color: $--primary;
  width: 60vw;
  padding: 7px 0 7px 0;
  color: $--white;
  text-align: center;
  border-radius: 5px;
}




// 검색 style
/* 주소입력창 */
// 검색창 + 검색결과 
#search_wrap {
  position:fixed;
  top:55px;
  left: 0;
  //transform: translate(-50%);
  width:100%;
  height: 150px;
  padding:10px;
  overflow-y:auto;
  background: $--grey;
  opacity: 0.8;
  z-index: 3;
  font-size:12px;
  border-radius: 10px;
}
// 검색창 + 검색결과 
.bg_white {
  background:#5F5F5F;
}
#search_wrap hr {
  display: block;
  height: 1px;
  border: 0; 
  border-top: 2px solid #5F5F5F;
  margin:3px 0;
}

// 검색결과 없는 경우
#search_wrap .results .noResults{
  padding: 0 5px 0 5px;
}

#search_wrap .searchbox{
  text-align: center; 
}
#search_wrap .searchbox .search{
  background-color: $--white;
  width: 100%;
  border-radius: 5px;
  height: 30px;
  margin-bottom: 5px;
  font-size: 16px;
  padding-left: 5px;

}

#search_wrap .searchbox p {
  margin:10px 0;
  }  
/* #search_wrap .searchbox button {
  margin-left:5px;
} */
// 검색창 스크롤바 숨기기
#search_wrap::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera*/
}
#search_wrap .results .places{
  cursor: pointer;
  margin-bottom: 3px;
  padding: 0 5px 0 5px
}
#search_wrap .results .places:hover{
  background-color:$--white;
  border-radius: 5px;
  
}

.btn_selectplace{
  z-index: 30;
  background-color: $--primary;
  width: 100%;
  margin-top: 30px;
  padding: 5px 40px 5px 40px;
  color: $--white;
  text-align: center;
  border-radius: 5px;
  transform: translate(0,100%);
  box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
}

#pagination {margin:10px auto;text-align: center;}
#pagination a {display:inline-block;margin-right:10px;}
#pagination .on {font-weight: bold; cursor: default;color:#777;}

// input화살표 없애기
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
// 토스트 스타일
.Vue-Toastification__toast--default{
  background-color: $--black !important;;
  opacity: 0.9 !important;;
  color: $--white !important;
}
// toggle 버튼 초기화
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default{
  height: fit-content !important;
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: $--white !important;
}
.theme--light.v-btn {
  color: #287BFF; // 버튼 클릭시 색깔
}
// 버튼 위에 hover중일때 아무런 effect 없게끔 blank
// .theme--light.v-btn:hover::before {
//   //color: #5F5F5F;
//   opacity: 0.5 !important;
// } 

// }
// 버튼 눌렀을때 
.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  opacity: 0.5;
}

.theme--light.v-messages{
  color : red;
}
// balance info
.activebalance .balanceinfo{
  position: absolute;
  top: 105px;
  right: 40px;
  height: 50px;
  z-index: 50;
  background-color: $--black;
  border-radius: 8px;
  display: flex;
  padding: 7px 10px 10px 10px;
}
.activebalance .balanceinfo .btn_close{
  margin-left: 30px;
}
.activebalance .balanceinfo .content{
  font-size: 13px;
  color: $--white;
  line-height: 1.5em;
}



// Progress circle Style
.EditReq .submit_progress .overlay{
  position: fixed;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 70;
  background: rgba(216, 208, 208, 0.5);
}
.EditReq .submit_progress .circle{
  position: fixed;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 71;
}


.EditReq .versionbox{
  position: absolute;
  right: 30px;
  top: 200px;
  padding: 10px;
  color: black;
  z-index: 1000;
  background-color: white;

}
.EditReq .beforeLoading .overlay{
  position: fixed;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 70;
  background: rgb(255, 255, 255);
}
.EditReq .beforeLoading .circle{
  position: fixed;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 71;
}
// @media (hover: hover) {
//   a:hover { color: #287BFF; }
// }
</style>
