<template>
  <div class="WalkThroughPage">
    <!--★ Map 보여주는 곳 ★ -->
    <div class="kmap" ref="map"></div>    
  </div>

</template>

<script>
//import {db} from './firebaseConfig'
import "firebase/firestore";

  // ★ 라이브러리를 Index.html에 정의했기에 vue특성상 전역변수 window를 써줘야 동작함 ★
  let kakao = window.kakao;

export default {
  name: 'WalkThroughPage',
  data() {
    return{
      // WalkTrough페이지 샘플 Marker 좌표 정보
      postDB:[
				{ // 잠실역 2번 출구
					lat:37.5129483686308, lng: 127.100802865937
				},
				{ // 방이동 먹자골목
					lat:37.5147825360342, lng: 127.109220856293
				},
				{ // 석촌호수 서호
					lat:37.5076807262772, lng: 127.099112837006
				},
				{ // 석촌호수 서호(2)
					lat:37.5076807262772, lng: 127.099112837006
				},
				{ // 석촌역 8호선 1번 출구
					lat:37.5057077279329, lng: 127.10713793760799
				},
				{ // 석촌호수 동호
					lat:37.5116212351376, lng: 127.105899949393
				},
				{ // 롯데월드
					lat:37.51113059993883, lng: 127.09811980036908
				},
				{ // 아펠가모 잠실점
					lat:37.5160560806548, lng: 127.099562794076
				},
			],

      // 지도 초기 옵션값 (잠실역 2번 출구)
      options:{
        center: {
          lat: 37.5129483686308,
          lng: 127.100802865937,
        },
        level: 5,
      },
    }
  },// End Data
  methods:{
   

  },// End Method
  mounted(){
  
  /// ★ Step1. 카카오 지도 생성 ★

  kakao = kakao || window.kakao;
  // 지도를 담을 영역의 DOM 레퍼런스
  var container = this.$refs.map; 
  // 초기 option값(롯데월드)
  const { center, level } = this.options
  // 지도 생성 및 객체 리턴 
  this.mapInstance = new kakao.maps.Map(container, {
    center: new kakao.maps.LatLng(center.lat, center.lng),
    level,
  }); 

	/// ★ Step2. 내 위치 포인트 표시 (Overlay방식으로) ★
	var gps_content = '<div ><img src="https://ssl.pstatic.net/static/maps/m/pin_rd.png" alt="" style="width:20px; height:20px;"></div>' // 내 위치 이미지
	this.currentOverlay = new kakao.maps.CustomOverlay({
			position: new kakao.maps.LatLng(this.options.center.lat,this.options.center.lng), // 현재 내 위치 
			content: gps_content,
			map: this.mapInstance
	});
	this.currentOverlay.setMap(this.mapInstance);

  /// ★ Step3. Marker & Cluster 생성 ★

	// 마커 클러스터러를 생성합니다  (clusterer)
	this.clusterer = new kakao.maps.MarkerClusterer({
			map: this.mapInstance, // 마커들을 클러스터로 관리하고 표시할 지도 객체 
			averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정 
			minLevel: 1, // 클러스터 할 최소 지도 레벨 
	});
	// 마커 이미지 설정
	var imageSrc = require("@/images/customPin.png"), 
	imageSize = new kakao.maps.Size(42, 42), // 마커이미지의 크기
	imageOption = {offset: new kakao.maps.Point(20, 43)}; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
	var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);

	// 마커 추가
	this.markers = this.postDB.map(function(position) {
		var marker = new kakao.maps.Marker({
				image: markerImage, // 마커이미지 설정 
				position : new kakao.maps.LatLng(position.lat, position.lng)
		});
		return marker
	});

	// 클러스터러에 마커들을 추가
	this.clusterer.addMarkers(this.markers);




  },// End Mounted
  
}
</script>

<style lang="scss">
@import "@/assets/common.scss";
.kmap{
  width: 100%;
  height: 100vh;
}

.WalkThroughPage .tempbtn{
  position: fixed;
  right: 10px;
  top: 70px;
  z-index: 10;
  padding: 10px;
  background-color: aqua;

}

.progress{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 50;
}


</style>