<template>
  <!-- main -->
  <div class="main">
    <router-view></router-view>
  </div>

</template>

<script>


export default {
  name: 'App',
  components: {

  },// Components
  data() {
    return{

    }
  },// End Data
  methods:{

  },// End Method
};
</script>

<style lang="scss">
@import "../src/assets/common.scss";

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Roboto;
  overscroll-behavior-y: none;
}
.main{
  width: 100%;
  height: 100vh;
  // -webkit-overflow-scrolling: touch; 
}
</style>
