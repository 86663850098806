<template>
  <div class="PostAlarmAdmin">
    <!--★ Map 보여주는 곳 ★ -->
    <div class="kmap" ref="map"></div>

    <!-- 에러개선 GPS정보를 받아오기 전에 default 좌표지도가 보이지 않게 가려버림 -->
    <div class="progress" v-if="progress_UI==true">
    </div>

    <div class = "debugMarkbottom" v-if="CollectionType =='post_debug'">
      Debug 1.0
    </div>
    
  </div>

</template>

<script>
// import {db} from './firebaseConfig'
import "firebase/firestore";
// import {geohashQueryBounds} from "geofire-common";
// import {distanceBetween} from "geofire-common";


  // ★ 라이브러리를 Index.html에 정의했기에 vue특성상 전역변수 window를 써줘야 동작함 ★
  let kakao = window.kakao;

export default {
  name: 'PostAlarmAdmin',
  components: {
    // eslint-disable-next-line

  },// Components
  data() {
    return{
      // 지도 초기 옵션값
      options:{
        center: {
          lat: 37.28305700556231,
          lng: 127.04366229466967,
        },
        level: 4,
      },
			// DB에서 가져온 request 데이터
      requestdb:[],

    }
  },// End Data
  methods:{
    /// (에러개선)indext.html파일에 환경변수 미사용을 위해 KakaoHome에서 넣어준다.
    loadScript() {
      const script = document.createElement("script");
      script.type="text/javascript";
      script.src =
        "https://dapi.kakao.com/v2/maps/sdk.js?appkey=73c00a61d9ef7c9d832f91190c37f6f9&libraries=services,clusterer,drawing"; 
      script.onload = () => window.kakao.maps.load(this.loadMap); // 스크립트 로드가 끝나면 지도를 실행될 준비가 되어 있다면 지도가 실행되도록 구현

      document.head.appendChild(script); // html>head 안에 스크립트 소스를 추가
    },

  },// End Method
  beforeMount(){
    //ApptoWeb GPS정보 전달 및 Webview Mount시 동작코드
    // window["PostAlarmAdmin"] = {
    //   component: this,
    //   mygps: (position,postCollection,blockList) => this.mygps(position,postCollection,blockList),
    // }


  },// End BeforeMount
  mounted(){
    if (window.kakao && window.kakao.maps) {
      //skip
    } else {
      // 없다면 카카오 스크립트 추가 후 맵 실행
      this.loadScript();
    }


  // ★ 라이브러리를 Index.html에 정의했기에 vue특성상 전역변수 window를 써줘야 동작함 ★
  kakao = kakao || window.kakao;
  //지도를 담을 영역의 DOM 레퍼런스
  var container = this.$refs.map; 

  // 초기 option값
  const { center, level } = this.options

  // ★ 지도 생성 및 객체 리턴 ★
  this.mapInstance = new kakao.maps.Map(container, {
    center: new kakao.maps.LatLng(center.lat, center.lng),
    level,
  }); 

  // Pinch zoom level변경 감지 이벤트 리스너 추가
  kakao.maps.event.addListener(this.mapInstance, 'zoom_changed', ()=>{        
    // 지도의 현재 레벨을 얻어옵니다
    var level = this.mapInstance.getLevel();
    if(level >= 7){
      this.toast3_ui=true
      setTimeout(()=> {
        this.toast3_ui= false;
      }, 5500);
    }
  });


  },// End Mounted
  
}
</script>

<style lang="scss">
@import "@/assets/common.scss";
.kmap{
  width: 100%;
  height: 100vh;
}


.progress{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 50;
}

.debugMarkbottom{
  position: fixed;
  left: 5px;
  bottom: 10px;
  z-index: 10;
  padding: 10px;
  color : black;
}



</style>